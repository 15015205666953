<template>
  <div v-if="hasErrors" class="p-4 rounded-md bg-red-50">
    <div class="flex items-center">
      <div v-if="hasErrors" class="text-red-800 ms-3">
        <div
          v-for="(errorsArray, field) in props.errors"
          :key="field"
          class="flex items-center justify-start"
        >
          <CloseIcon class="w-5 h-5 mr-1 text-red-400" />
          <p
            v-for="(errorMsg, key) in errorsArray"
            :key="key"
            v-text="errorMsg"
          />
        </div>
      </div>
    </div>
  </div>
</template>

<script setup>
import CloseIcon from '~/assets/svg/heroicons/x-circle-fill.svg?component'

const props = defineProps({
  errors: {
    type: Object,
    default: () => ({}),
  },
})

const hasErrors = computed(() => {
  return Object.keys(props.errors).length > 0
})
</script>
